import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import "./App.scss";
import { Footer, LoaderPageFetch, Navbar } from "components";
import { useAppSelector } from "hooks";
import routes from "routes";
import theme from "theme/muiTheme";

export default function App() {
  // Hooks state
  const isLoading = useAppSelector((state) => state.app.isLoading);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Box>
          <Navbar />
          {routes}
          <Footer />
        </Box>
      </BrowserRouter>

      <Toaster />
      {isLoading && <LoaderPageFetch />}
    </ThemeProvider>
  );
}
